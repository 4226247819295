import {routeConfig} from '$utils';
import {priviledges} from '$consts';

export default [
  routeConfig('Map', {title: 'Map', ...priviledges.E}, () => import('../views/Map.vue')),
  routeConfig('map/v2', {title: 'Map', ...priviledges.D}, () => import('../views/map/page.vue')),
  routeConfig('map/v2/magic/:magic_id', {title: 'Map & Snap', topRow: false, ...priviledges.D}, () => import('../views/map/magic/page.vue')),
  routeConfig('map/v2/devices/:device_id', {title: 'Map', ...priviledges.D}, () => import('../views/map/page.vue')),
  routeConfig('map/v2/trips/:trip_id', {title: 'Map', ...priviledges.D}, () => import('../views/map/page.vue')),
  routeConfig('Trips', {title: 'Trip', ...priviledges.D}, () => import('../views/Trips.vue')),
  routeConfig('trips/:id', {title: 'Trip', ...priviledges.D}, () => import('../views/Trips.vue')),
  routeConfig('Videos', {title: 'Videos', ...priviledges.E}, () => import('../views/videos/page.vue')),
  routeConfig('videos/:id', {
    title: 'Videos',
    ...priviledges.E,
  }, () => import('../views/videos/page.vue')),
  {
    name: 'CameraTimeline',
    path: '/camera-timeline',
    component: () => import('../views/camera-timeline/page.vue'),
    meta: {
      auth: true,
      topRow: true,
      title: 'Camera Timeline',
      ...priviledges.D,
    },
  },
  {
    name: 'drivers',
    path: '/drivers',
    component: () => import('../views/drivers/page.vue'),
    meta: {
      auth: true,
      topRow: true,
      title: 'Drivers',
      ...priviledges.G,
    },
    children: [
      {
        path: 'needs-attention',
        component: () => import('../views/drivers/needs-attention/page.vue'),
      },
      {
        path: 'all-drivers',
        component: () => import('../views/drivers/all-drivers/page.vue'),
      },
      {
        path: 'profile/:id',
        component: () => import('../views/drivers/profile/page.vue'),
        children: [
          {
            path: 'edit',
            component: () => import('../views/drivers/profile/edit/page.vue'),
          }
        ]
      }
    ],
  },
  {
    name: 'Analytics',
    path: '/analytics',
    component: () => import('../views/analytics/page.vue'),
    meta: {
      auth: true,
      topRow: true,
      title: 'Analytics',
      ...priviledges.E,
    },
    children: [
      {
        path: 'fleet-risk',
        component: () => import('../views/analytics/fleet-risk/page.vue'),
      },
      {
        path: 'incident-report',
        component: () => import('../views/analytics/incident-report/page.vue'),
      },
      {
        path: 'driver-risk',
        component: () => import('../views/analytics/driver-risk/page.vue'),
      },
      {
        path: 'accident-report',
        component: () => import('../views/analytics/accident-report/page.vue'),
      },
      {
        path: 'driver-detection-report',
        component: () => import('../views/analytics/driver-detection-report/page.vue'),
      },
    ],
  },
  {
    name: 'Devices',
    path: '/devices',
    component: () => import('../views/devices/page.vue'),
    meta: {
      auth: true,
      topRow: true,
      title: 'Devices',
      ...priviledges.D,
    },
    children: [
      {
        path: 'active',
        component: () => import('../views/devices/active/page.vue'),
      },
      {
        path: 'inactive',
        component: () => import('../views/devices/inactive/page.vue'),
      },
    ],
  },
  routeConfig(
    'Share',
    {
      title: 'Share',
      ...priviledges.G,
    },
    () => import('../views/share/page.vue'),
  ),
  routeConfig('Hos', {title: 'HOS', ...priviledges.E}, () => import('../views/hos/page.vue')),
  {
    name: 'Profile',
    path: '/profile',
    component: () => import('../views/profile/page.vue'),
    meta: {
      auth: true,
      topRow: true,
      title: 'Profile',
      ...priviledges.J,
    },
    children: [
      {
        path: 'preferences',
        component: () => import('../views/profile/preferences/page.vue'),
      },
      {
        path: 'user-details',
        component: () => import('../views/profile/user-details/page.vue'),
      },
      {
        path: 'change-password',
        component: () => import('../views/profile/change-password/page.vue'),
      },
      {
        path: 'api-tokens',
        component: () => import('../views/profile/api-tokens/page.vue'),
      },
    ],
  },
  {
    name: 'Settings',
    path: '/settings',
    component: () => import('../views/settings/page.vue'),
    meta: {
      auth: true,
      topRow: true,
      title: 'Settings',
      ...priviledges.B,
    },
    children: [
      {
        path: 'incident-settings',
        component: () => import('../views/settings/incident-settings/page.vue'),
      },
      {
        path: 'incident-webhook',
        component: () => import('../views/settings/incident-webhook/page.vue'),
      },
    ],
  },
  {
    name: 'Coaching',
    path: '/coaching',
    component: () => import('../views/coaching-messages/page.vue'),
    meta: {
      auth: true,
      topRow: true,
      title: 'Coaching',
      ...priviledges.D,
    },
    children: [
      {
        path: 'fleet/:fleet_id/driver/:driver_id',
        component: () => import('../views/coaching-messages/conversation/page.vue'),
      },
    ],
  },
  {
    name: 'Users',
    path: '/users',
    component: () => import('../views/users/page.vue'),
    meta: {
      auth: true,
      topRow: true,
      title: 'Users',
      ...priviledges.B,
    },
    children: [
      {
        path: 'active',
        component: () => import('../views/users/active/page.vue'),
      },
      {
        path: 'invited',
        component: () => import('../views/users/invited/page.vue'),
      },
    ],
  },
  {
    name: 'Fleets',
    path: '/fleets',
    component: () => import('../views/fleets/page.vue'),
    meta: {
      auth: true,
      topRow: true,
      title: 'Fleets',
      ...priviledges.B,
    },
  },
  routeConfig('Policy', {title: 'Policy', ...priviledges.G}, () => import('../views/policy/page.vue')),
  routeConfig('Unassigned', {
    topRow: false,
    ...priviledges.D,
  }, () => import('../views/Unassigned.vue')),
];
