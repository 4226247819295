import { ax } from './helpers';
interface GetRecsForDevicePayload {
  id: String;
  from: Number;
  to: Number;
}
export const getRecsForDevice = async (payload: GetRecsForDevicePayload) => {
  const from = payload.from.getTime() / 1000;
  const to = payload.to.getTime() / 1000;
  const res = await ax.get(`/v2/device/${payload.id}/recs?from=${from}&to=${to}`).catch((e) => e.response);
  if (res.status !== 200) return [];
  return res.data;
};

interface GetDeviceStitcherPayload {
  from: Number;
  to: Number;
  id: Number;
  limit: Number;
  offset: Number;
};
export const getDeviceStitcher = async (payload: GetDeviceStitcherPayload) => {
  const from = payload.from.getTime() / 1000;
  const to = payload.to.getTime() / 1000;
  const url = `/device/${payload.id}/stitcher?from=${from}&to=${to}&limit=${payload.limit}&offset=${payload.offset}`;
  const res = await ax.get(url).catch((e) => e.response);
  if (res.status !== 200) return;
  return res.data;
};

interface UpdateDeviceDetailPayload {
  device_id: String;
  name: String;
  vin: String;
  coaching: Boolean;
  api_enabled: Boolean;
  dis: Boolean;
  inactive: Boolean;
};
export const updateDeviceDetail = async (payload: UpdateDeviceDetailPayload) => {
  await ax.put(`/device/${payload.device_id}/details`, {
    name: payload.name,
    api_enabled: payload.api_enabled,
    vin: payload.vin,
    coaching: payload.coaching,
    dis: payload.dis,
    inactive: payload.inactive,
  });
};

interface GetDeviceTripsV3 {
  device_id: String;
  offset: Number;
  limit: Number;
  driver_ids: Array<Number>;
  incidents: Array<String>;
  until: Number;
};
const getDeviceTripsV3 = async (payload: GetDeviceTripsV3) => {
  const driverIdsQuery = payload.driver_ids.reduce((a: any, i: any) => a + '&' + 'driver_ids=' + i, '');
  const incidentsQuery = payload.incidents.reduce((a: any, i: any) => a + '&' + 'incidents=' + i, '');
  const res = await ax.get(`/v3/device/${
    payload.device_id
  }/trips?${
    driverIdsQuery
  }${
    incidentsQuery
  }&until=${payload.until}&offset=${payload.offset}&limit=${payload.limit}`);
  return res.data;
};

export default {
  install: {
    start: async (deviceId) => {
      const res = await ax.get(`/device/${deviceId}/install/start`).catch((e) => e.response);
      if (res.status !== 200) return false;
      return res.data;
    },
  },
  trips: async (deviceId, options = {}) => {
    const res = await ax.get(`/v2/device/${deviceId}/trips`, {
      params: {
        since: options.since && options.since.getTime() / 1000,
        until: options.until && options.until.getTime() / 1000,
        offset: options.offset,
        limit: options.limit,
        show_hidden: options.showHidden,
      },
    });
    return res.data;
  },
  tripsV3: getDeviceTripsV3,
  incidents: async (deviceId, year, month) => {
    const res = await ax.get(`/device/${deviceId}/${year}/${month}/incidents`)
      .catch((e) => e.response);
    if (res.status !== 200) return;
    return res.data;
  },
  tripsAdmin: async (deviceId, until) => {
    const res = await ax.get(`/device/${deviceId}/trips/admin`, {
      params: {
        until: until / 1000,
      },
    });
    return res.data;
  },
  settings: {
    get: async (deviceId) => {
      const res = await ax.get(`/device/${deviceId}/settings`);
      return res.data;
    },
    log: async (deviceId, until) => {
      const res = await ax.get(`/device/${deviceId}/settings/log?until=${until / 1000}`);
      return res.data;
    },
  },
  setting: {
    post: async (deviceId, name, value) => {
      await ax.post(`/device/${deviceId}/setting/${name}`, { value });
    },
    delete: async (deviceId, name) => {
      await ax.delete(`/device/${deviceId}/setting/${name}`);
    },
    postAll: async (name, value) => {
      await ax.post(`/device/setting/${name}`, { value });
    },
    deleteAll: async (name) => {
      await ax.delete(`/device/setting/${name}`);
    },
  },
  restart: async (id) => {
    await ax.post(`/device/${id}/restart`);
  },
  driver: async (deviceId, driverId) => {
    await ax.put(`device/${deviceId}/driver`, { driver_id: driverId });
  },
  automaticAssignment: async (deviceId, enable) => {
    await ax.put(`/device/${deviceId}/automatic-assignment`, { enable });
  },
  details: updateDeviceDetail,
  history: async (deviceId) => {
    const res = await ax.get(`/device/${deviceId}/history`);
    return res.data;
  },
  driverHistory: async (deviceId) => {
    const res = await ax.get(`/device/${deviceId}/drivers/history`);
    return res.data;
  },
  updateInternal: async (deviceId, packageName) => {
    await ax.put('/device/update-internal', { device_id: deviceId, package_name: packageName });
  },
  updateStatus: async (deviceId) => {
    const res = await ax.get(`/device/${deviceId}/update/status`);
    return res.data;
  },
  dashboard: async (deviceId) => {
    const res = await ax.get(`/device/${deviceId}/qa-dashboard`);
    return res.data;
  },
  status: async (deviceId) => {
    const res = await ax.get(`/device/${deviceId}/status`);
    return res.data;
  },
  fleet: async (deviceId, fleetId) => ax.put(`/device/${deviceId}/fleet`, { id: fleetId }),
  deliver: async (deviceId, fleetId) => ax.put(
    `/device/${deviceId}/deliver`,
    { fleet_id: fleetId },
  ),
  heat: async (ids, since, until) => {
    const res = await ax.post('/device/heat', {
      ids,
      since: since / 1000,
      until: until / 1000,
    });
    return res.data;
  },
  coords: async (deviceId, until) => {
    const res = await ax.get(`/device/${deviceId}/coords`, {
      params: { until: until / 1000 },
    });
    return res.data;
  },
  cmd: {
    during: async (since, until, sinceId) => {
      const res = await ax.get('/device/cmd/during', {
        params: {
          since: since && since.getTime() / 1000,
          until: until && until.getTime() / 1000,
          sinceId,
        },
      });
      return res.data;
    },
    details: async (id) => {
      const res = await ax.get(`/device/cmd/${id}`);
      return res.data;
    },
  },
  cannedCmd: async (deviceId, name) => {
    const res = ax.post(`/device/${deviceId}/cmd/canned/${name}`);
    return res.data;
  },
  stitcher: getDeviceStitcher,
  recs: {
    post: async (deviceId, until, statuses = null) => {
      const res = await ax.post(`/device/${deviceId}/recs`, {
        until: until / 1000,
        statuses,
      });
      return res.data;
    },
    get: getRecsForDevice,
  },
  snaps: async (deviceId, until) => {
    const res = await ax.get(`/device/${deviceId}/snaps?until=${until / 1000}`);
    return res.data;
  },
  note: {
    get: async (deviceId, until) => {
      const res = await ax.get(`/device/${deviceId}/note?until=${until / 1000}`);
      return res.data;
    },
    post: async (deviceId, body) => {
      await ax.post(`/device/${deviceId}/note`, { body });
    },
  },
  shutdown: async (id) => {
    await ax.post(`/device/${id}/shutdown`);
  },
};
